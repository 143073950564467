.portfolioCard {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  min-height: 250px;
  box-shadow: 0px 0px 50px 0px #7e97a862;
  margin: 20px;
  position: relative;
}
.portfolioCard:hover {
  box-shadow: 0px 0px 50px 0px #7e97a8a6;
}
.cardContainer {
  margin: 42px;
}
.imgContainer {
  width: 100%;
  margin-bottom: 30px;
}
.img {
  width: 50px;
}

.knowMoreButton {
  position: absolute;
  bottom: 9%;
  left: 9%;
}

.title {
  color: #252844;
  margin-bottom: 20px;
}
.text {
  color: #9e9e9e;
}
