.contactContainer {
  width: 90%;
  margin: 0 5%;
  display: flex;
}

.map {
  flex: 60%;
}

.contactForm {
  flex: 40%;
  display: flex;
  flex-direction: column;
  margin: 3%;
}

.formHeader {
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
  margin-left: 3%;
  color: #252844;
}

.formContainer {
  margin: 5%;
}

.formContainer label {
  color: #485272;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.inputbox {
  border: 1px solid #dfe4ea;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  color: #252844;
  font-size: 2em;

  margin-bottom: 6%;
}

.inputbox:focus {
  outline: none;
  border: 2px solid #005388;
  border-radius: 4px;
  
}

.nameinput,
.mailinput {
  height: 15%;
}

.messageinput {
  overflow-y: scroll;
  height: 40%;
  resize: none;
}

.submitbutton {
  padding: 2% 5%;
  background: #758794;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  border: none;
  border-radius: 4px;
  transition: background-color .6s ease;
}

.submitbutton:hover {
    background: #005388;
    cursor: pointer;
}

@media (max-width: 1200px) {
  .contactContainer {
    flex-direction: column;
  }
}
