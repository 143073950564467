.footer {
  background-color: #005388;
}
.wrapper {
  width: 100%;
  /* outline: 2px solid red; */
  display: flex;
  justify-content: center;
}
.container {
  width: 90%;
  display: flex;
  padding-top: 40px;
  /* outline: 2px solid black; */
}
.contact,
.about,
.programs,
.announcements,
.media {
  height: 350px;
  flex: 3;
  margin-top: 2%;
  /* outline: 2px solid blue; */
}
.media {
  /* outline: 5px solid red; */
}
.programs {
  flex: 2;
  /* outline: 2px solid yellow; */
}

/*logo styling*/
.logo {
  display: flex;
  width: 50%;
  align-items: center;
  /* outline: 2px solid black; */
}
.logotext {
  display: flex;
  border-left: 0.5px solid white;
  font-family: Mardoto, serif;
  height: 31px;
  color: #373435;
  margin-left: 0.3rem;
}
.logotext h2 h6 {
  color: white;
  margin: 0;
  padding-left: 0.2rem;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
}
.logoNameBig,
.logoNameSmall {
  color: white;
  margin: 0;
  padding-left: 0.2rem;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
}

/*email styling*/
.email {
  margin-top: 100px;
}
.inputContainer {
  display: flex;
  align-items: flex-end;
}
.emailText {
  margin-bottom: 30px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
}
.inputContainer ::placeholder {
  color: white;
}
.input {
  border: none;
  background-color: #005388;
  border-bottom: 1px solid rgba(255, 255, 255, 0.432);
  outline: none;
  color: white;
  height: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
}
.btnImage {
  width: 7px;
}
.button {
  width: 50px;
  height: 50px;
  background-color: white;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/*about styling*/

.aboutHeader {
  color: white;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23181818425655365px;
  text-align: left;
}
.aboutText {
  margin-top: 25px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.20454545319080353px;
  text-align: left;
}
.aboutLink {
  text-decoration: none;
  color: white;
}

/*styling footerBottom*/
.socialIcons {
  border-top: 1px solid rgba(255, 255, 255, 0.205);
  padding-top: 11px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.socilaIconsContainer {
  width: 88%;
  display: flex;
  justify-content: flex-end;
}
.icon {
  margin-left: 11px;
  width: 40px;
}
@media (max-width: 900px) {
  .container {
    flex-wrap: wrap;
  }
  .contact {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about,
  .programs,
  .announcements,
  .media {
    min-width: 120px;
    margin-left: 30px;
  }
}
