.wrapper {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.container {
  width: 80%;
  display: flex;
}

.content {
  flex: 5;
  margin-right: 5%;
}
.rightMenu {
  flex: 2;
}


@media (max-width: 1200px) {
  .container {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  
  .content {
    flex: 1;
    margin-right: 0;
  }
  .rightMenu {
    flex: 1;
    width: 72%;
  }

}