.moreButton {
    width: 15em;
    color: #005388;
    transition: 0.5s;
}

.moreButton:hover {
    opacity: 0.7;
    cursor: pointer;
}

.buttonText {
    font-size: 1em;
    line-height: 150%;
    letter-spacing: 0.02em;
}

.buttonimg {
    width: 6%;
    margin-left: 5%;
}