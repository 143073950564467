.wrapper {
  margin-top: 40px;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  width: 85%;
}
.bulletinImage {
  height: 365px;
  width: 100%;
  border-radius: 12px;
}
.imageContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
/* .arrowWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  height: 50px;

}
.arrowBox {
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.arrowContainer {
  width: 80px;
  justify-content: flex-end;
  display: flex;
  right: 5%;
  position: absolute;

}
.arrow1 {
  margin-right: 15px;
  width: 15px;
  height: 18px;
  clip-path: polygon(75% 0, 31% 48%, 78% 100%, 42% 100%, 0% 50%, 46% 0);
  background-color: #005388;
}
.arrow2 {
  width: 15px;
  height: 18px;
  clip-path: polygon(32% 0, 80% 48%, 37% 100%, 0 100%, 50% 48%, 0 0);
  background-color: #005388;
} */
.arrowsContainer {
  display: flex;
  justify-content: flex-end;
}

.arrowLeft,
.arrowRight {
  margin: 2% 3% 2% 1%;
  opacity: 0.6;
}
.arrowLeft:hover,
.arrowRight:hover {
  opacity: 1;
  cursor: pointer;
}