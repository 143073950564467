.publContainer {
  width: 86%;
  margin: 0 7% 7% 7%;
}

.arrowsContainer {
  display: flex;
  justify-content: flex-end;
}

.arrowLeft,
.arrowRight {
  margin: 2%;
  opacity: 0.6;
}
.arrowLeft:hover,
.arrowRight:hover {
  opacity: 1;
  cursor: pointer;
}

.sliderContainer {
  display: flex;
}

.sliderLeft {
  flex: 60%;
}

.sliderRight {
  flex: 40%;
  width: 40%;
  margin-left: 15%;
}
/* .link {
  height: auto;
} */
.imgContainer {
  height: auto;
  position: relative;
}
.publImg {
  /* height: 500px; */
  width: 100%;
  height: 100%;
}

.blackFon {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 1;
}

.publHeader {
  position: absolute;
  bottom: 20%;
  left: 20%;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #ffffff;
  z-index: 2;
}

.publText {
  position: absolute;
  bottom: 10%;
  left: 47%;
  width: 46%;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #ffffff;
  z-index: 2;
}

@media (max-width: 1200px) {
  .sliderLeft {
    display: none;
  }

  .sliderRight {
    flex: 100%;
    margin-left: 0;
  }

  .publImg {
    height: 300px;
  }

  .blackFon {
    height: 300px;
  }
}
