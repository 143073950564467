.header {
  width: 86%;
  height: 75px;
  padding: 0 7%;
  display: flex;
  position: fixed;
  top: 0;
  border-bottom: 1px solid #dfe4ea;
  z-index: 1000;
  background-color: white;
}

.header .leftSide {
  /* flex: 15%; */
  display: flex;
  align-items: center;
  /* outline: 1px solid rgb(179, 88, 126); */
}
a {
  text-decoration: none;
  color: #485272;
}
.header .rightSide {
  flex: 80%;
  /* outline: 1px solid rgb(51, 22, 47); */
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header .leftSide img {
  /* width: 50%; */
  margin: 1em 0.5em 1em 1em;
}

.logotext {
  display: flex;
  border-left: 0.5px solid #605d5c;
  font-family: Mardoto, serif;
  height: 31px;
  color: #373435;
}
.logotext h2,
h6 {
  margin: 0;
  padding-left: 0.2rem;
}

.menuitem {
  position: relative;
  font-size: 16px;
  color: #485272;
  height: 45px;
  padding-top: 30px;
}

.menuitem:hover {
  color: #005388;
  cursor: pointer;
}

.menuitemlink:hover {
  color: #005388;
  cursor: pointer;
}

.rightSide .menuitem img {
  width: auto;
  position: absolute;
  bottom: 42%;
  margin-left: 0.5em;
}
.searchForm {
  display: flex;
}
.input {
  border-bottom: 2px solid #005388;
  font-size: 16px;
  color: #485272;
  height: 45px;
  padding-top: 10px;
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
}
.searchIcon {
  padding-top: 25px;
}

.lang {
  margin-left: 3%;
}
/* 
.dropdown {
    position: absolute;
} */

.mobileRightSide {
  display: none;
}
@media (max-width: 1500px) {
  .header {
    width: 96%;
    padding: 0 2%;
  }
  .searchForm {
    flex: 13%;
  }
  .input {
    width: 100%;
  }
  .lang {
    margin-left: 1%;
  }
}
@media (max-width: 1200px) {
  .header .rightSide {
    display: none;
  }


  .mobileRightSide {
    flex: 30%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
  }
  .searchForm {
    flex: 60%;
    margin-left: 15%;
  }
  .lang {
    margin-left: 2%;
  }
}
@media (max-width: 500px) {
  .header .rightSide {
    display: none;
  }

  .mobileRightSide {
    flex: 10%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
  }

  .menuIcon {
    height: 25%;
  }
  .searchForm {
    flex: 30%;
    margin-left: 3%;
  }

  .header .leftSide img {
    width: 28%;
  }
  .logotext {
    height: 20px;
    font-size: 11px;
  }
  .lang {
    margin-left: 5%;
  }
  
}
