.wrapper {
  width: 86%;
  margin: auto;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.constainer {
  width: 84%;
  /* outline: 2px solid black; */
  margin-left: 8%;
}
.memberContainer {
  width: 100%;
}
@media (max-width: 850px) {
  .memberContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
