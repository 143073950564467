.wrapper {
  width: 100%;
  /* outline: 3px solid yellow; */
  justify-content: center;
  margin-bottom: 100px;
}
.container {
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  /* outline: 3px solid black; */
}
.headerConatiner {
  margin-bottom: 85px;
}
.rulesTitles {
  flex: 2;
  /* outline: 3px solid red; */
}
.rulesContent {
  flex: 3;
  /* outline: 3px solid blue; */
}
.hedearText {
  margin-top: 75px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0.02em;
  text-align: center;
}
.hr {
  width: 67px;
  background-color: #005388;
  height: 2px;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
  }
}
