.list {
  list-style: none;
}

.title {
  margin: 0;
  padding: 10px 20px;
  border-left: 2px solid #d9dbe9;
}
.titleLink {
  text-decoration: none;
  color: #485272;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.activeBorder {
  border-left: 2px solid #005388;
}

.activeLink {
  color: #005388;
}
