.newsDetails {
  width: 86%;
  margin: auto;
  margin-top: 150px;
}
.container {
  display: flex;
}

.newsContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
}

p img {
  max-width: 100% !important;
}
.rightSide {
  margin-left: 8%;
  width: 30%;
}
/* 
.longtext {
  margin: 5% 0;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.02em;

  color: #485272;
} */

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
  }
  .newsContainer {
    width: 90%;
  }
  .rightSide {
    margin-left: 8%;
    width: 90%;
  }
}
