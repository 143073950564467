.program1Content {
  display: flex;
  flex-direction: row;
  width: 86%;
  margin: auto;
  /* justify-content: space-around; */
}
.markedText {
  width: 70%;
}
.publications {
    margin-left: 8%;
  width: 30%;
}
.markedText {
  display: flex;
  flex-direction: column;
}

.secondPublication {
  margin-top: 70px;
}

.program1Slider {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 100px;
}
p img {
  max-width: 100% !important;
}

@media (max-width: 1200px) {
  .program1Content {
    flex-direction: column;
  }
  .markedText {
    width: 90%;
  }
  .publications {
    width: 90%;
  }
}
