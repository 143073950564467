.topic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 80px auto;
  width: 86%;
}

.h1 {
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #252844;
}

.hr {
  width: 67px;
  background-color: #005388;
  height: 2px;
  border: none;
}

@media (max-width: 1200px) {
  .publicationsArea {
    flex-direction: column;
  }
  .h1 {
    font-size: 1.5rem;
  }
}
