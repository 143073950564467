.news {
    width: 100%;
}

.unussedPaginationItem {
    display: none;
}

.pagination {
    width: 35%;
    margin-top: 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.paginationItemCharacter {
    /* font-family: "Poppins"; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
}

.paginationItem {
    width: 43px;
    height: 43px;
    background: #FFFFFF;
    border: 1px solid #E7E8EA;
    border-radius: 50%;
    color: #252844, 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.currentPaginationItem {
    width: 43px;
    height: 43px;
    border: 1px solid #E7E8EA;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #005388;
    color: #FFFFFF;
}

@media (max-width: 1200px) {
    .news {
        width: 100%;
    }
  }
  