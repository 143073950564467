.dropdown {
    position: absolute;
    left: -1em;
    border-top: 1px solid #005388;
    background: #ffffff;
    top: 4.5em;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    z-index: 2;
    
  }
  
  .dropdown ul {
    list-style-type: none;
    font-size: 14px;
    padding-left: 0;
    width: 16rem;
    /* font-family: Mardoto; */
  }
  
  .dropdown li {
    letter-spacing: 0.02em;
    padding: 1em 0 1em 2em;
  }
  .dropdown li a {
    text-decoration: none;
    color: #252844;
  }
  
  .dropdown li:hover {
    background-color: #f2f6f9;
    cursor: pointer;
    color: #005388;
  }
  
  @media (max-width: 1200px) {
    .dropdown {
      left: -8em;
    }
    .dropdown ul {
      font-size: 20px;
    }
  }
  @media (max-width: 500px) {
    .dropdown {
      left: -13em;
    }
    .dropdown ul {
      font-size: 20px;
    }
  }