.slider {
  height: 650px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: opacity ease-in-out 0.4s;
}

.activAnim {
  opacity: 1;
  z-index: 2;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blackFon {
  position: absolute;
  top: 0;
  background: #000000;
  opacity: 0.4;
  height: 100%;
  width: 100%;
}

.linesContainer {
  position: absolute;
  bottom: 15%;
  left: 20%;
  z-index: 2;
  display: flex;
}

.line {
  width: 100px;
  height: 3px;
  background-color: #c4c4c4;
  opacity: 0.68;
}

.line:hover {
  cursor: pointer;
}

.lineActiv {
  width: 100px;
  height: 6px;
  background-color: #005388;
}

.slideText  {
  position: absolute;
  color: #FFFFFF;
  top: 30%;
  left: 8%;
  text-decoration: none;
  width: 40%;
}

.slideText .title {
  font-size:  40px;
  line-height: 130%;
  font-weight: bold;
  letter-spacing: 0.02em;
}

.slideText .text {
  font-size: 20px;
  line-height: 150%;
}

.slideNumbering {
  position: absolute;
  bottom: 15%;
  left: 17%;
  color: #FFFFFF;
  display: flex;
}

.slideNumbering .scaled {
  transform: scale(1.3); 
  margin-right: 20%;
}

@media (max-width: 1200px) {
  .slideText  {

    width: 80%;
  }
  
  .slideText .title {
    font-size:  30px;
  }
  
  .slideText .text {
    font-size: 18px;
  }
  .slideNumbering {
    position: absolute;
    bottom: 15%;
    left: 10%;

  }
  

}