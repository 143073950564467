.newsItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #e7e8ea;
  padding: 2%;
}
.newsRightContent {
  margin-left: 5%;
  width: 50%;
  position: relative;
}

.newsItemImage {
  width: 40%;
  /* height: 249px; */
  border-radius: 5px;
}

.newsItemP {
  color: #485272;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
}

.newsItemDate {
  /* height: 21px; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #005388;
  margin-top: 10%;
}

.newsItemH2 {
  /* width: 378px; */
  /* height: 31px; */
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #252844;
}

.newsItemH6 {
  /* width: 138px; */
  /* height: 13px; */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #86899b;
}

@media (max-width: 1200px) {
  .newsItem {
    flex-direction: column;
  }
  .newsItemImage {
    width: 90%;
  }
  .newsRightContent {
    width: 90%;
  }
}
