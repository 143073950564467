

.header {
  margin: 3% 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #252844;
}

.member {
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #252844;
  margin: 1% 0;
}
