.programsContainer {
    width: 90%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    margin: 0 5%;

}

.arrowsContainer {
    margin-right: 5%;
    display: flex;
    justify-content: flex-end;
  }
  
  .arrowLeft,
  .arrowRight {
    margin: 0 2%;
    opacity: 0.6;
  }
  .arrowLeft:hover,
  .arrowRight:hover {
    opacity: 1;
    cursor: pointer;
  }