.pageContainer {
    margin-top: 150px;
}

.logos {
  width: 86%;
  margin: 0 7%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.logoContainer {
  margin: 1%;
  width: 225px;
  padding: 3% 0 0 4%;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(126, 151, 168, 0.24);
  height: 100px;
}
.logoContainer:hover{
    box-shadow: 0px 0px 7px rgb(126, 151, 168);
}

.donorLogo {
  width: auto;
  height: 50%;
}
.partners {
    background-color: rgba(222, 238, 254, 0.5);
    padding-bottom: 3%;
    margin-bottom: 4%;
}