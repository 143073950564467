
.h3 {
    /* max-width: 190px;
    max-height: 23px; */
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: #252844;
}

.publicationList {

    display: flex;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .publicationList {
        width: 86%;
        margin: auto;
    }
  }