.card {
  /* outline: 1px solid red; */
  display: flex;
  width: 60%;
  margin: 65px 0;
}
.imageContainer {
  margin-right: 7%;
  /* outline: 3px solid blue; */
}
.imageContainer img {
height: 350px;
  float: right;
}
.textContainer {
  flex: ;
  /* outline: 3px solid pink; */
  min-width: 600px;
}
.name {
  margin-bottom: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #252844;
}
.position {
  margin-top: 7px;
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #252844;
}
.bio {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}
.email {
  margin-top: 50px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #005388;
}

.socialLinks {
  margin-bottom: 0;
}
@media (max-width: 750px){
  .card {
    min-width: 400px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .textContainer {
    min-width: 250px;
    width: 76%;
  }
}