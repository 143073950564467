.imgContainer {
    height: 700px;
    width: 86%;
    position: relative;
    overflow: hidden;
  }
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1%;
  }
  .activAnim {
    opacity: 1;
  }
  
  .btnSlider {
    width: 60px;
    height: 60px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btnSlider img {
    width: 15px;

  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
  
  .dotsContainer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 8px;
    background: #E5E5E5;
  }
  .activDot {
    background: #005388;
  }

  @media (max-width: 800px) {
    .imgContainer {
        height: 120px;
      }
  }