.publItem {
  padding: 3% 0 1% 0;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  /* height: 30%; */
}

.imgSide {
  flex: 20%;
  margin: auto;
}
.textSide {
  flex: 80%;
  position: relative;
  margin-bottom: 2%;
}

.publImg {
  width: 100%;
}

.header {
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #252844;
  margin: 2% 2%;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #485272;
  margin: 2% 2%;
}

.buttonMore {
  position: absolute;
  top: 95%;
  margin: 0 2%;
}
