.portfolioItem {
  flex: 20%;
  position: relative;
  height: 240px;
  transition: 0.4s;
}



.portfolioItem img {
  width: 100%;
  height: 240px;
  position: absolute;
}

.item1 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 83, 136, 0.664);
}

.item2 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
}

.item3 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(122, 135, 148, 0.8);
}
.item4 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(255, 204, 62, 0.8);
}

.item5 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(21, 98, 40, 0.8);
}

.portfolioItem:hover {
  opacity: 0.8;
}

.title {
  position: absolute;
  z-index: 2;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 150%;
  width: 80%;
  top: 10%;
  left: 5%;
  text-transform: uppercase;
}

.button {
  position: absolute;
  z-index: 2;
  bottom: 10%;
  left: 5%;
}
@media (max-width: 1365px) {
  
  .title {
    font-size: 1.1em;

  }
}
@media (max-width: 900px) {
  .portfolioItem {
    flex: 33%;
    
  }

}

@media (max-width: 600px) {
  .portfolioItem {
    flex: 100%;
  }
  .title {
    font-size: 1.5em;

  }
}
