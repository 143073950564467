.image {
    margin: 2% 0;
    flex: 20%;
    width: 15%;
    border-radius: 4px;
   
}

.h4 {
    flex: 80%;
    color: #485272;
    font-family: "Mardoto";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    margin-left: 3%;
}

.publication {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #E7E8EA;
    border-bottom: 1px solid #E7E8EA;
    justify-content: space-between;
    align-items: center;
}