.listItem,
.listTitle {
  display: flex;
  border-bottom: 1px solid #D9DBE9;
  padding-bottom: 20px;
  margin-top: 20px;
}
.programsContainer {
  margin-bottom: 80px;
}
.listTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #252844;
}
.linkItem {
  text-decoration: none;
  width: 100%;
  color: #485272;
  cursor: pointer;
}
.itemContainer {
  display: flex;
  width: 100%;
}
.itemImage {
  flex: 1;
  width: 12%;
  border-radius: 10%;
}
.itemTitle {
  flex: 7;
  margin-left: 15px;
}
