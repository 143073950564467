.itemsContainer {
  display: flex;
  width: 85%;
  flex-wrap: wrap;
}

.itemsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 150px;
  margin-top: 80px;
}
.portfolioItem {
  flex: 1;
}
