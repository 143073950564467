.mymapbox {
  margin: 5% 5%;
}

.mymap {
  height: 500px;
  border-radius: 2em;
}
.maptext {
  color: #485272;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  display: flex;
  flex-direction: column;
}
