.main {
  width: 100%;
  justify-content: center;
}
.wrapper {
  width: 88%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-left: 6%;
}

.container {
  width: 100%;
}
.aboutCenterContainer {
  width: 100%;
  display: flex;
  /* outline: 1px solid red; */
}

.textArea {
  flex: 3;
  /* outline: 10px solid black; */
}
.imgArea {
  flex: 4;
  display: flex;
  justify-content: center;
  /* outline: 6px solid black; */
}
.aboutCenterText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #485272;
}
.textHeader {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #252844;
  margin-bottom: 60px;
}
.aboutCenterImage {
  width: 80%;
}
.possibilitiesContainer {
  margin-top: 80px;
  width: 100%;
  display: flex;
}
.leftText {
  flex: 3;
}
.rightText {
  flex: 4;
  /* outline: 1px solid red; */
}
.row1,
.row2 {
  display: flex;
}
.box {
  flex: 1;
  height: 250px;
  margin-left: 80px;
  margin-top: 40px;
  /* outline: 1px solid black; */
}
.boxHeader {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #252844;
}
.boxText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #485272;
}
.sliderContainer {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}


@media (max-width: 1050px) {
  .aboutCenterContainer {
    flex-direction: column;
  }
  .textArea {
    margin-bottom: 50px;
  }
  .possibilitiesContainer {
    flex-direction: column;
  }
  .rightText {
    margin-top: 60px;
  }
  .box {
    margin: 0;
  }
  .textHeader {
    font-size: 26px;
  }
}