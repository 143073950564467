.programBox {
  margin: 2%;
  flex: 15%;
  box-shadow: 0px 0px 7px rgba(126, 151, 168, 0.24);
  border-radius: 5px;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
}

.programBox:hover {
  box-shadow: 0px 0 20px rgba(88, 101, 110, 0.24);
  cursor: pointer;

}

.programBox .programimg {
  width: 100%;
}

.programBox .title {
  width: 80%;
  font-weight: 500;
  font-size: 1.6em;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #252844;
  padding: 2% 5%;
  color: #252844;
}

.programBox .text {
  font-size: 1em;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #86899b;
  padding: 2% 5%;
  height: 150px;
  flex: 80%;
}

.buttonBox {
  padding: 2% 5%;
  flex: 10%;
}


