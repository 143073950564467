.policyPaper {
  width: 86%;
  margin: 0 7%;
  display: flex;
  flex-direction: column;
}

.publicationsArea {
  display: flex;
  flex-direction: row;
}
.newsArea {
  width: 70%;
}

.rightSide {
  width: 30%;
  margin-left: 20%;
}

.pageNumbers {
  list-style: none;
  display: flex;
  width: 35%;
  justify-content: space-around;
}

.pageNumbers li {
  width: 43px;
  height: 43px;
  background: #ffffff;
  border: 1px solid #e7e8ea;
  border-radius: 50%;
  color: #252844, 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pageNumbers li.active {
  width: 43px;
  height: 43px;
  border: 1px solid #e7e8ea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #005388;
  color: #ffffff;
}

.pageNumbers li button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.notFound {
  width: 60%;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 130%;
  color: #252844;
}

@media (max-width: 1200px) {
  .publicationsArea {
    flex-direction: column;
  }
  .rightSide {
    margin-left: 0;
    width: 86%;
  }
  .pageNumbers {
    width: 86%;
  }
  .newsArea {
    width: 100%;
  }
  .notFound {
    margin-bottom: 150px;
  }
}
